import * as React from "react"
import {faPhone, } from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"


export default function Contact({phone}: {phone?: string}) {

    return (
        <a
            className='btn bg-orange w-100 text-uppercase'
            href={`https://wa.me/${phone}`}
        >
            <FontAwesomeIcon className='px-2' icon={faPhone} /> Contactez-nous
        </a>
    )
}
