import * as React from "react"
import {AppLayout} from "../layouts"
import {useParams} from "react-router"
import {Booking, Contact, Loader, MetaHeading, Pricing, VehicleInfo, VehicleTitle} from "../components"
import {remoteGet} from "../remotes/api"
import { VehicleType } from "../type-defs"


export default function VehicleScreen() {

    const [vehicle, setVehicle] = React.useState<VehicleType | undefined >(undefined)
    const [loading, isLoading] = React.useState<boolean>(true)
    const signal = new AbortController()

    let { slug } = useParams();

    const fetchData = () => {
        remoteGet(`vehicles/${slug}`, signal)
        .then(response => setVehicle(response))
        .finally(() => isLoading(false))
    }

    React.useEffect(() => {

        fetchData()

        return () => signal.abort()

    }, [slug])

    if (loading || vehicle === undefined) {
        return <Loader />
    }

    return (
        <AppLayout>

            <MetaHeading
                title={vehicle.designation}
                description={vehicle?.description}
                imageUrl={vehicle?.medias[0]?.url}
            />

            <React.Fragment>
                <div className='container'>
                    <div className='row'>

                        <div className='col-md-8 col-12'>
                            <VehicleTitle {...vehicle} />
                            <VehicleInfo  {...vehicle} />
                        </div>

                        <div className='col-md-4 col-12'>

                            <Pricing {...vehicle} />

                            <Booking {...vehicle} />

                            {vehicle && vehicle.phone && vehicle.phone?.length > 0 && (
                                <>
                                    <hr/>
                                    <div className='small text-muted my-2 text-center'>
                                        Demandez une prise en charge directement en nous appelant directement.
                                    </div>
                                    <Contact phone={vehicle.phone} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </AppLayout>
    )
}
