import * as React from "react"
import {GoBack} from "../index"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import VehicleType from "./VehicleType";



export default function VehicleTitle({designation, displayedPrice, currency, model,}: VehicleType) {
    return (
        <>
            <div className='d-flex align-items-center'>
                <GoBack url='/' />
                <h1 className='px-4 h5'>{designation}</h1>
            </div>
            <div className='text-danger mb-2 d-flex justify-content-between'>
                <strong>{model}</strong>
                <span className='bg-success px-1 small rounded text-white text-uppercase'>
                    <FontAwesomeIcon className='px-2' icon={faCheckCircle} />
                    Validé
                </span>
            </div>
        </>
    )
}
