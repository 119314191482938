import * as React from "react"
import {faBook, faCheckCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {InputText, Loader, SelectDate} from "../index"
import {remotePost} from "../../remotes/api"
import VehicleType from "./VehicleType";

const signal = new AbortController()

export default function Booking({slug, price, currency, only_selling, for_sale}: VehicleType) {

    const [dateInfo, setDateInfo] = React.useState<any>({})
    const [loading, isLoading] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [phone, setPhone] = React.useState<string>('')
    const [start_date, setStartDate] = React.useState<string>('')
    const [end_date, setEndDate] = React.useState<string>('')
    const [error, setError] = React.useState<string>('')
    const [booked, isBooked] = React.useState<boolean>(false)

    const handleBooking = async () => {

        try {
            // start loading process
            isLoading(true)

            // Reset the error
            setError('')

            // Remote api
            const response = await remotePost(`vehicles/${slug}/booking`, {
                name,
                email,
                phone,
                start_date,
                end_date,
                dial: '+225'
            }, signal) as any

            if (response && response.response) {
                isBooked(true)
            }

        } catch (e : any) {

            const statusUnprocessable = e.response.status === 422
            if (statusUnprocessable) {

                const errors = e.response.data.errors as [];
                const firstErrorMessage = Object.values(errors)[0][0] as string;

                setError(firstErrorMessage)
            }
        } finally {
            isLoading(false)
        }
    }


    return (
        <>
            {! only_selling && (
                <section>
                    {for_sale && (
                        <div className='alert alert-info small'>
                            Ne renseignez pas les dates si votre seul intérêt est d'acheter le véhicule.
                        </div>
                    )}

                    <div className='row'>
                        <div className='col-6'>
                            <InputText
                                label='Date de début'
                                text={start_date}
                                type='date'
                                uid='start_date'
                                onChanged={(text: string) => setStartDate(text) }
                            />
                        </div>
                        <div className='col-6'>
                            <InputText
                                label='Date de fin'
                                text={end_date}
                                type='date'
                                uid='end_date'
                                onChanged={(text: string) => setEndDate(text) }
                            />
                        </div>
                    </div>
                </section>

            )}

            <>
                <InputText
                    label='Nom complet'
                    text={name}
                    uid='full_name'
                    placeholder='Renseignez votre nom ici'
                    onChanged={(text: string) => setName(text) }
                />

                <InputText
                    label='Téléphone'
                    placeholder='Rensignez votre numéro de téléphone ici'
                    type='tel'
                    text={phone}
                    uid='phone'
                    onChanged={(text: string) => setPhone(text) }
                />

                <InputText
                    label='Adresse email'
                    placeholder='XXXXX@YYYY.COM'
                    text={email}
                    uid='email'
                    onChanged={(text: string) => setEmail(text) }
                />
            </>

            {error.length > 0 && (
                <p className='alert alert-danger text-center small py-2'>
                    <FontAwesomeIcon className='px-2' icon={faInfoCircle} />
                    {error}
                </p>
            )}

            {loading && <Loader text="Réservation en cours ..." />}

            {booked && <BookedMessage />}

            {!loading &&  !booked && (
                <button className='btn btn-success w-100 my-2 font-weight-bold small' onClick={handleBooking}>
                    <FontAwesomeIcon className='px-2' icon={faBook} />
                    <span className='text-uppercase'>Réservez</span> {dateInfo && dateInfo.duration && (
                        <span className='small'>
                            pour {(parseInt(dateInfo.duration) * parseFloat(price)).toFixed(2)} {currency}
                        </span>
                    )}
                </button>
            )}
        </>
    )
}

const BookedMessage = () => {
    return (
        <>
            <div className='text-center my-3 alert alert-success p-2'>
                <h5>Votre réservation a été créée !</h5>
                <FontAwesomeIcon className='mr-2 fa-3x text-success' icon={faCheckCircle} /><br/>
                Un message de confirmation vous a également été envoyé par email !
            </div>
        </>
    )
}
