export default function ForSaleBadge() {
    return <div className="position-absolute ForSale">
        <span className="badge bg-warning p-2 text-uppercase">Véhicule à vendre</span>
    </div>
}

export  function SellingPrice({price}: {price: string}) {
    return <div className="alert alert-warning text-center text-uppercase">
        <strong className="">Prix de vente : {price}</strong>
    </div>
}