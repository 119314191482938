import * as React from "react"
import styles from './Vehicle.module.css'
import {Link} from "react-router-dom"
import VehicleType from "./VehicleType"
import {Contact, ForSaleBadge, ImageSlider, SellingPrice} from "../index"
import {faBook} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"


export default function VehicleItem({id, slug, designation, price, only_selling, currency, box, energy, seat, medias, bodyCar, phone, displayedPrice, for_sale, selling_price}: VehicleType) {
    return (
        <div  className='col-md-4 col-12 mb-5'>
            <div className='rounded'>

                <div className='mb-4 position-relative'>
                    <ImageSlider images={medias} />
                    {for_sale && <ForSaleBadge />}
                </div>

                <div className='d-flex justify-content-between'>
                    <h6 className='w-100 text-uppercase'>{designation}</h6>
                    {!only_selling && (
                        <h6 className='w-100 d-flex justify-content-end'>
                            <strong className='text-danger h5'>{displayedPrice} {currency}</strong>
                            <sup className='font-weight-light'>/ jour</sup>
                        </h6>
                    )}

                </div>

                {for_sale && selling_price && <SellingPrice price={`${selling_price} ${currency ?? ''}`} />}

                <div className='small'>
                    <div>
                        <span className={styles.Badge}>Boîte {box?.toLowerCase()}</span>
                        <span className={styles.Badge}>{energy}</span>
                        <span className={styles.Badge}>{seat} places</span>
                        <span className={styles.Badge}>{bodyCar}</span>
                    </div>
                </div>

                <div className='d-flex justify-content-between my-2 align-items-center'>
                    <div className='px-1 w-100'>
                        <Contact phone={phone} />
                    </div>
                    <div className='px-1 w-100'>
                        <Link className='text-decoration-none text-dark' to={`/v/${slug}`}>
                            <button className='btn bg-green text-uppercase w-100'>
                                <FontAwesomeIcon className='px-2' icon={faBook} />
                                <small>Réservez</small>
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

