import * as React from "react"
import {faBox, faChair, faCarBattery, faCar, faDriversLicense} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import styles from './Vehicle.module.css'
import {ForSaleBadge, ImageSlider} from "../index";
import VehicleType from "./VehicleType";

export default function VehicleInfo({seat, energy, bodyCar, box, withDriver, equipments, medias, description, for_sale}: VehicleType) {

    return (
        <>
            <div className='mb-4 position-relative'>
                <ImageSlider images={medias} />
                {for_sale && <ForSaleBadge />}
            </div>
            <div className='row mb-4 small'>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faBox} />
                    <span className='px-2'>{box}</span>
                </div>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faChair} />
                    <span className='px-2'>{seat} places</span>
                </div>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faCarBattery} />
                    <span className='px-2'>{energy}</span>
                </div>
                <div className='col-6'>
                    <FontAwesomeIcon icon={faCar} />
                    <span className='px-2'>{bodyCar}</span>
                </div>
            </div>

            {withDriver && (
                <div className='alert alert-info text-center font-weight-bold'>
                    <FontAwesomeIcon className='px-2' icon={faDriversLicense} />
                    Véhicule fourni avec un conducteur
                </div>
            )}

            <div className='text-muted my-3' dangerouslySetInnerHTML={{ __html: (description as string) }} />

            <div className='my-3'>
                {(equipments as [])?.length > 0 && <>
                    <h5>Les équipements</h5>
                    {equipments?.map(({id, name}: {id: string, name: string}) => (
                        <span key={id} className={styles.Badge}>{name}</span>
                    ))}
                </>}
                
            </div>
        </>
    )
}
