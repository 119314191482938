import * as React from "react";
import {contactUs, contactUsForWhatApp} from "../utils";

export default function ContactUs() {

    return <div className="ContactUs small">
        <a href={`mailto:contact@loca-ci.com`}>
            <span className='badge badge-pill bg-orange'>contact@loca-ci.com</span>
        </a>

        <a href={`https://wa.me:/${contactUs()}`}>
            <span className='badge badge-pill bg-green'>{contactUsForWhatApp()}</span>
        </a>
    </div>
}

