import * as React from "react";
import VehicleType from "./VehicleType";
import { SellingPrice } from "../ForSaleBadge";

export default function Pricing({displayedPrice, currency, for_sale, selling_price, only_selling}: VehicleType) {
    return (
        <>
            {! only_selling && (
                <div className="d-flex justify-content-end">
                    <h3 className='d-inline mr-2 text-danger'>
                        <strong>{displayedPrice} {currency}</strong>
                    </h3>
                    <sup>/ jour</sup>
                </div>
            )}

            {for_sale && selling_price && <SellingPrice price={`${selling_price} ${currency ?? ''}`} />}
            <hr/>
        </>
    )
}
